import { endpointsMd } from "utils/metadata-split/constant";
import * as Yup from "yup";

export type offlineUploadFilterType = {
  created_from: string;
  created_till: string;
  updated_from: string;
  updated_till: string;
};

const subDays = (days) => {
  let date: any = new Date();
  date.setDate(date.getDate() - days);
  return date;
};

export const initDateObj: any = {
  from: subDays(7),
  to: new Date(),
};

export const offlineUploadInitialValues: offlineUploadFilterType = {
  created_from: "",
  created_till: "",
  updated_from: "",
  updated_till: ""
};

export const offlineUploadMandatoryFields = [
  { id: "created_from" },
  { id: "created_till" },
  { id: "updated_from" },
  { id: "updated_till" },
];

export const offlineUploadValidationSchema = Yup.object().shape({
  // created_from: Yup.date(),
  // created_till: Yup.date(),
});


export const offlineUploadFilterProperties = [
  {
    id: "created_from",
    type: "date-picker",
    label: "Created From",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    leadGen:true,
    dateType:"start",
    relative:"created_till"
  },
  {
    id: "created_till",
    type: "date-picker",
    label: "Created Till",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    leadGen:true,
    dateType:"end",
    relative:"created_from"
  },
  {
    id: "updated_from",
    type: "date-picker",
    label: "Updated From",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    leadGen:true,
    dateType:"start",
    relative:"updated_till"
  },
  {
    id: "updated_till",
    type: "date-picker",
    label: "updated Till",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    leadGen:true,
    dateType:"end",
    relative:"updated_from"
  },
]


export const offlineUploadFilterCreateProperties = [
  {
    id: "created_from",
    type: "date-picker",
    label: "Created From",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
  {
    id: "created_till",
    type: "date-picker",
    label: "Created Till",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
]