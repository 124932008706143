import { Route, Switch } from "react-router-dom"
import { ParseLeadsGeneration } from "./routes/parse"
import { UploadLeadsGeneration } from "./routes/upload"
import { SubmitCsvLeadsGeneration } from "./routes/upload/submit-csv"

export const Leadsgeneration=({ match }: { match: any })=>{
    return(
        <>
        <Switch>
           <Route exact path={`${match.url}/upload`} component={UploadLeadsGeneration}/> 
           <Route exact path={`${match.url}/submit_csv`} component={SubmitCsvLeadsGeneration}/> 
           <Route exact path={`${match.url}/parse`} component={ParseLeadsGeneration}/> 
        </Switch>
        </>
    )
}