import React, { useState, useRef } from "react";
import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DownloadIcon from "assets/download-icon.svg"; // Update the path accordingly
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import leadsGenerateService from "../../service";
import Amplify, { Storage } from "aws-amplify";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppDispatch } from "hooks/redux";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Loader } from "./components/Loader";
import "./styles.css";
interface PresignedResponse {
  data?: {
    status: string;
    data: {
      presigned_put_url: string;
      bucket_url: string;
    };
  };
}

const useStyles: any = makeStyles(() => ({
  Btn: {
    backgroundColor: "#3f50b5",
    color: "white",
    width: "90px",
  },
  BtnWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "center",
  },
}));

export const SubmitCsvLeadsGeneration = () => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [bucketUrl, setBucketUrl] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files?.[0];
      if (!file) return;
      setSelectedFile(file);
    } catch (error) {
      console.error("File upload error:", error);
      setSelectedFile(null);
      setBucketUrl("");
    }
  };

  const handleFileBrowse = () => {
    fileInputRef.current?.click();
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (selectedFile) {
      try {
        const res: any = await leadsGenerateService.UploadFileNamePresigned({
          file_name: selectedFile?.name,
          mime_type: "text/csv",
        });

        if (res?.data?.status !== "success") {
          setLoading(false);
          throw new Error("Failed to get presigned URL");
        }

        const { presigned_put_url, bucket_url } = res.data.data;
        setBucketUrl(bucket_url);

        const uploadResponse = await fetch(presigned_put_url, {
          method: "PUT",
          body: selectedFile,
          headers: {
            "Content-Type": "text/csv",
          },
        });
        if (uploadResponse.ok) {
          const resOffline: any = await leadsGenerateService.offlineUploadCsvs({
            upload_csv_public_url: `${bucket_url}`,
          });
          if (res?.data?.status == "success") {
            setLoading(false);
            setSelectedFile(null);
            dispatch(
              snackbarRequest({
                message: "Successfully Uploaded",
                type: "success",
              })
            );
          } else {
            setLoading(false);
            dispatch(
              snackbarRequest({ message: res.data.message, type: "error" })
            );
          }
        } else {
          setLoading(false);
          setBucketUrl("");
          throw new Error(`Upload failed: ${uploadResponse.statusText}`);
        }
      } catch (error) {
        console.error("File upload error:", error);
        // You might want to add error state handling here
        setSelectedFile(null);
        setBucketUrl("");
        setLoading(false);
      }
    } else {
      console.log("No file selected");
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: "20px", display: "flex", justifyContent: "center" }}>
      <Paper elevation={3} sx={{ width: "50%", padding: "20px" }}>
        <Box>
          {/* Hidden File Input */}
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />

          {/* Input and Buttons */}
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
          >
            <TextField
              label="File Name"
              variant="standard"
              fullWidth
              disabled
              value={selectedFile ? selectedFile.name : ""}
              // helperText="(Max file size: 50MB)"
              sx={{ marginRight: "10px" }}
            />
            <Button
              variant="contained"
              startIcon={<CloudDownloadIcon />}
              onClick={handleFileBrowse}
              // className={classes.Btn}
            >
              Browse
            </Button>
          </Box>

          {/* Submit and Download Buttons */}
          <Box className={classes.BtnWrapper}>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!selectedFile}
            >
              Submit
            </Button> */}
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              color="primary"
              onClick={handleSubmit}
              loading={loading}
              disabled={!selectedFile}
              sx={{ width: "160px" }}
            >
              <UploadFileIcon sx={{ marginRight: "5px" }} /> Upload File
            </LoadingButton>
          </Box>
          {/* {loading &&  <div className="loader"></div>} */}
        </Box>
      </Paper>
    </Box>
  );
};
