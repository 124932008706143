import { Box, Paper } from "@mui/material"
import { TypographyHeading } from "shared/molecules/typography-layout"

export const ParseLeadsGeneration=()=>{
    return (
        <div>
        <Box>
        <Paper>
           <Box sx={{width:"70%",height:'400px',marginTop:"25px",padding:"20px"}}>
             <TypographyHeading> parse table data</TypographyHeading>

           </Box>
        </Paper>
        </Box>
    </div>
    )
}