import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import ContentRight from "shared/molecules/content-right";
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import { TypographyHeading } from "shared/molecules/typography-layout";
import { OffineUploadFilter } from "./filter";
import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/redux";
import leadsGenerateService from "pages/leads-generation/service";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
//import { Loader } from "./components/Loader";
import Loader from "pages/build-test/loader";
import { TableRowCollapse } from "./components/TableRowCollapse";

const initialData = [
  {
    id: 17,
    upload_csv_public_url:
      "https://example-bucket.s3.amazonaws.com/uploaded-file-1.csv",
    processed_csv_public_url:
      "https://example-bucket.s3.amazonaws.com/processed-file-1.csv",
    status: "processed",
    total_records_processed: 100,
    error: null,
    created_at: "2024-12-03T17:10:20.474Z",
    updated_at: "2024-12-03T17:11:01.041Z",
    created_by_id: 123,
  },
  {
    id: 18,
    upload_csv_public_url:
      "https://example-bucket.s3.amazonaws.com/uploaded-file-2.csv",
    processed_csv_public_url:
      "https://example-bucket.s3.amazonaws.com/processed-file-2.csv",
    status: "failed",
    total_records_processed: 50,
    error: "Error processing CSV due to invalid format",
    created_at: "2024-12-03T12:43:05.810Z",
    updated_at: "2024-12-03T12:43:16.787Z",
    created_by_id: 124,
  },
];
const useStyles: any = makeStyles((indigoTheme) => ({
  label: {
    textDecoration: "none",
    textAlign: "center",
    color: indigo[700],
    marginLeft: "80%",
  },
  Btn: {
    backgroundColor: "#3f50b5",
    color: "white",
    width: "90px",
  },
  BtnWrapper: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginRight: "20px",
  },
}));

export const UploadLeadsGeneration = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (newPage < page) {
      const value = offset - limit;
      setOffset(value);
    } else {
      const value = offset + limit;
      setOffset(value);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setLimit(+event.target.value);
    setOffset(0);
    setPage(0);
  };

  const getOfflineCsv = async (payload) => {
    setLoading(true);
    const res: any = await leadsGenerateService.getOfflineUploadCsvs(payload);
    if (res?.data?.status == "success") {
      setTotalCount(res?.data?.data?.total_count);
      setData(res?.data?.data?.list);
      setLoading(false);
    }
  };
  useEffect(() => {
    getOfflineCsv({ ...filterData, limit, offset });
  }, [offset, filterData, limit]);

  return (
    <div>
      <ContentRight>
        <Link to="/leadsgenerations/submit_csv" className={classes.label}>
          <Button variant="contained">upload csv</Button>
        </Link>
      </ContentRight>
      <TypographyHeading>Leads Generation</TypographyHeading>
      <Box>
        <Box sx={{ marginTop: "30px" }}>
          <OffineUploadFilter
            setFilterData={setFilterData}
            setPage={setPage}
            setOffset={setOffset}
          />
        </Box>
        <Paper>
          <Box
            sx={{
              width: "100%",
              // height: "400px",
              marginTop: "25px",
              padding: "20px",
            }}
          >
            {loading && <Loader />}
            <TableContainer component={Paper}>
              <Table>
                <TableRow
                  sx={{
                    backgroundColor: "grey.200",
                    fontSize: "45px",
                    width: "100%",
                    "& td": {
                      fontSize: "15px",
                      fontWeight: "bold",
                      textAlign:"center"
                    },
                  }}
                >
                  <TableCell>Uploaded CSV</TableCell>
                  <TableCell>Processed CSV</TableCell>
                  <TableCell>Total Records</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Error</TableCell>
                  <TableCell>Created at</TableCell>
                  <TableCell>Updated at</TableCell>
                </TableRow>
                <Box sx={{ width: "100%" }}></Box>
                {!loading &&
                  data?.map((row: any) => <TableRowCollapse row={row} />)}
              </Table>
            </TableContainer>
          </Box>
          <Box className={classes.BtnWrapper}>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={totalCount}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Paper>
      </Box>
    </div>
  );
};
