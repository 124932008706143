import { Button, Grid, Paper } from "@mui/material";
import { useAppDispatch } from "hooks/redux";
import useReactHookForm from "hooks/useReactHookForm";

import ContentRight from "shared/molecules/content-right";
import DynamicForm from "shared/organisms/dynamicform";
import {
  offlineUploadFilterProperties,
  offlineUploadInitialValues,
  offlineUploadValidationSchema,
} from "./helper";

export const OffineUploadFilter = ({ setFilterData,setPage,setOffset }) => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    offlineUploadInitialValues,
    offlineUploadValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();

  const handleSearch = () => {
    const obj = {
      created_from: watch()?.created_from,
      created_till: watch()?.created_till,
      updated_from: watch()?.updated_from,
      updated_till: watch()?.updated_till
    };
    setFilterData(obj);
    setPage(0);
    setOffset(0);
  };

  const handleClearData=()=>{
   setValue("created_from","")
   setValue("created_till","")
   setValue("updated_from","")
   setValue("updated_till","")
   setFilterData({});
   setPage(0);
   setOffset(0);
  }

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={offlineUploadFilterProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: 0 }}>
            <Button
              variant="contained"
              sx={{
                width: "90px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 20px 7px 20px",
                cursor: "pointer",
                textAlign: "center",
              }}
                onClick={handleClearData}
                disabled={!hasTruthyLengthValue(watch())}
            >
              clear
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "80px",
                height: "40px",
                marginTop: "15px",
                padding: "7px 42.5px 7px 48.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleSearch}
                 disabled={!hasTruthyLengthValue(watch())}
            >
              search
            </Button>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};


function hasTruthyLengthValue(obj) {
  return Object.values(obj).some(value => {
      // Convert Date objects to strings
      if (value instanceof Date) {
          value = value.toString();
      }
      // Check for strings with length > 0
      return typeof value === "string" && value.length > 0;
  });
}
