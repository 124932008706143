import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/redux";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { formatDateTime, truncateText } from "pages/leads-generation/helpers";
export const TableRowCollapse = ({ row }) => {
  const [open, setOpen] = useState(false);
  return (
    <TableBody sx={{}}>
      <TableRow
        key={row?.id}
        sx={{
          "& td": {
            textAlign: "center",
          },
        }}
      >
        <TableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              component="p" // Ensures the element is a <p> tag
            >
              Uploaded Csv
            </Typography>
            <a
              href={row?.upload_csv_public_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FileDownloadIcon />
            </a>
          </Box>
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography component="p">Processed csv</Typography>
            <a
              href={row?.processed_csv_public_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FileDownloadIcon />
            </a>
          </Box>
        </TableCell>
        <TableCell>{row?.total_records_processed}</TableCell>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                backgroundColor: row?.status === "processed" ? "green" : "red",
                fontWeight: "bold",
                color: "white",
                borderRadius: "10px",
                textAlign: "center",
                fontSize: "15px",
                width: "100px",
              }}
            >
              {row?.status}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Tooltip
            title={row?.error ? row?.error : "___"}
            placement="top"
            arrow
          >
            <Typography>
              {row?.error ? truncateText(row?.error) : "___"}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>{formatDateTime(row?.created_at)}</TableCell>
        <TableCell>{formatDateTime(row?.updated_at)}</TableCell>
      </TableRow>
    </TableBody>
  );
};
