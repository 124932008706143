import request from "utils/axios/service";
import { endpoints } from "./constants";

export default class leadsGenerateService {
  static UploadFileNamePresigned(payload) {
    return request.get(endpoints.LEADS_PRESIGNED, payload);
  }
  static offlineUploadCsvs(payload) {
    return request.post(endpoints.OFFLINE_UPLOAD_CSVS, payload);
  }
  static getOfflineUploadCsvs(payload) {
    return request.get(endpoints.GET_UPLOAD_CSVS, payload);
  }
}